import React from "react";
import styled from "styled-components";
import { formatPhoneData } from "../utilities/utils";

interface DataSlashProps {
  data: number;
  planTypeD: string;
}

const DataSlash: React.FC<DataSlashProps> = (props: DataSlashProps) => {
  return <DataSlashTitle>{formatPhoneData(props.data, props.planTypeD)}</DataSlashTitle>;
};

const DataSlashTitle = styled.h5`
  margin-top: -30px;
  text-decoration: line-through;
  color: #a0a0a0;
  font: normal normal 300 25px/30px Poppins;

  @media (max-width: 768px) {
    font: normal normal 300 15px/20px Poppins !important;
  }
`;
export default DataSlash;
