import React from "react";
import styled from "styled-components";
import PlanSelectCard from "./PlanSelectCard";

interface PlanSelectListProps {
  currency: string;
  country: string;
  planType: string;
  plans?: any[];
  enableShipping: boolean;
  enableESIM: boolean;
}

const PlanSelectList: React.FC<PlanSelectListProps> = (props: PlanSelectListProps) => {

  const blackFridayDeal = (id: number) => {
    switch (id) {
      case 1211:
      case 1216:
        return { deal: true, originalData: 15 };
      case 1212:
      case 1217:
        return { deal: true, originalData: 20 };
      case 1213:
      case 1218:
        return { deal: true, originalData: 25 };
      case 1214:
      case 1220:
        return { deal: true, originalData: 30 };
      default:
        return { deal: false, originalData: null };
    }
  };

  return (
    <Layout>
      {props.plans.map((p, i) => (
        <div key={i} style={{ padding: "5px" }}>
          <PlanSelectCard
            id={p.planId}
            price={p.plan_fee}
            data={p.data}
            carrier={p.carrier}
            isPromotion={p.plan_fee === 45}
            currency={props.currency}
            path="/plan-info"
            planTypeD={p.planTypeD}
            country={props.country}
            planType={props.planType}
            enableESIM={props.enableESIM}
            enableShipping={props.enableShipping}
            dataSlashAmount={blackFridayDeal(p.planId).originalData}
            plintronPlanId={p.plintronPlanId}
            subscriptionId={p.subscriptionId}
          // blackFriday={blackFridayDeal(p.planId).deal}
          // blackFridayOriginalDataAmount={blackFridayDeal(p.planId).originalData}
          />
        </div>
      ))}
    </Layout>
  );
};

const Layout = styled.div``;

export default PlanSelectList;
