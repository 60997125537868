import React, { useState } from "react";
import styled from "styled-components";
import ElevioArticle from "./ElevioArticle";

interface RightArrowProps {
  itemName: string;
}

const ElevioCategory: React.FC<RightArrowProps> = (props: RightArrowProps) => {
  const [isArrowRotated, setIsArrowRotated] = useState(false);
  const rotateArrow = () => {
    setIsArrowRotated(!isArrowRotated);
  };

  const SignUp = () => {
    return (
      <div>
        <ElevioArticle articleId="3" text="How does the online activation work?" />
        <ElevioArticle articleId="4" text="How can a traveler who has just arrived in Canada purchase a SIM card?" />
        <ElevioArticle articleId="6" text="Is it possible to schedule the activation date of the SIM card?" />
        <ElevioArticle articleId="5" text="Is it possible to purchase a SIM card outside of Canada?" />
        <ElevioArticle articleId="7" text="What plans are available on PhoneBox?" />
        <ElevioArticle articleId="8" text="How does a Monthly account work?" />
        <ElevioArticle articleId="9" text="How does a prepaid account work?" />
        <ElevioArticle articleId="10" text="How do I start using my PhoneBox SIM card?" />
      </div>
    );
  };

  const AccountChanges = () => {
    return (
      <div>
        <ElevioArticle articleId="18" text="Does PhoneBox charge a fee to change my plan?" />
        <ElevioArticle articleId="27" text="Is it possible to change the plan anytime?" />
        <ElevioArticle articleId="28" text="Can I upgrade or downgrade my plan?" />
        <ElevioArticle articleId="29" text="How can I change my plan from Prepaid to Postpaid?" />
        <ElevioArticle articleId="30" text="How can I change my plan from Postpaid to Prepaid?" />
      </div>
    );
  };

  const LeavingPortOutSuspension = () => {
    return (
      <div>
        <ElevioArticle articleId="31" text="How do I terminate my account?" />
        <ElevioArticle articleId="32" text="Can I schedule the termination date of my account?" />
        <ElevioArticle articleId="33" text="Do I get a refund for unused services when I terminate my account?" />
        <ElevioArticle articleId="34" text="When will I get my refund after terminating my account?" />
        <ElevioArticle articleId="35" text="How do I transfer my phone number to a different service provider?" />
      </div>
    );
  };

  const GeneralInformation = () => {
    return (
      <div>
        <ElevioArticle articleId="36" text="When will PhoneBox deliver a SIM card?" />
        <ElevioArticle articleId="37" text="Which plans are available at PhoneBox?" />
        <ElevioArticle articleId="38" text="How good is the PhoneBox network coverage in Canada?" />
        <ElevioArticle articleId="39" text="Why should I choose PhoneBox over other service providers?" />
        <ElevioArticle articleId="40" text="Do postpaid plans have a lock-in contract?" />
      </div>
    );
  };

  const ComplaintsAndFeedback = () => {
    return (
      <div>
        <ElevioArticle articleId="41" text="Why have I not received my SIM card yet?" />
        <ElevioArticle articleId="42" text="How can I track the delivery of my SIM card?" />
        <ElevioArticle articleId="43" text="The online activation didn't work for my US plan. What should I do?" />
        <ElevioArticle articleId="44" text="Why is the delivery of my SIM card delayed?" />
        <ElevioArticle articleId="45" text="Why is no one responding to my emails?" />
      </div>
    );
  };

  const MultilineAccounts = () => {
    return (
      <div>
        <ElevioArticle articleId="78" text="What are Multiline Accounts?" />
        <ElevioArticle articleId="79" text="What are the benefits of adding multiple accounts into one using the multi-lining account functionality?" />
        <ElevioArticle articleId="80" text="How do I add a line on my online account using the multi-lining option?" />
        <ElevioArticle articleId="81" text="How do I remove a line on my online account using the multi-lining option?" />
      </div>
    );
  };

  const Shipping = () => {
    return (
      <div>
        <ElevioArticle articleId="70" text="Does PhoneBox offer shipping of SIM cards?" />
      </div>
    );
  };

  const NetworkTechnicalSupport = () => {
    return (
      <div>
        <ElevioArticle articleId="16" text="What do I do when my phone is showing “No Service”?" />
        <ElevioArticle articleId="46" text="Why can't I make outgoing calls?" />
        <ElevioArticle articleId="47" text="Why can't I receive inbound calls?" />
        <ElevioArticle articleId="48" text="Why can't I send text messages?" />
        <ElevioArticle articleId="49" text="Why can't I receive text messages?" />
      </div>
    );
  };

  const BillingInquiry = () => {
    return (
      <div>
        <ElevioArticle articleId="50" text="Is it possible to set-up an auto-extension for my prepaid plan?" />
        <ElevioArticle articleId="51" text="What are the payment methods for prepaid plans?" />
        <ElevioArticle articleId="52" text="What are the payment methods for postpaid plans?" />
        <ElevioArticle articleId="53" text="What is the billing cycle for prepaid plans?" />
        <ElevioArticle articleId="54" text="What is the billing cycle for postpaid plans?" />
      </div>
    );
  };

  const PlansRoamingPromotion = () => {
    return (
      <div>
        <ElevioArticle articleId="12" text="How does a vacation plan work?" />
        <ElevioArticle articleId="13" text="What is the difference between prepaid and postpaid accounts?" />
        <ElevioArticle articleId="14" text="Can I extend my vacation plan?" />
        <ElevioArticle articleId="15" text="I forgot to extend my prepaid plan after the 30-day service. How can I make a payment?" />
        <ElevioArticle articleId="55" text="Do I get a notification when my prepaid plan is about to expire?" />
        <ElevioArticle articleId="56" text="Do I get a notification when I have exceeded my data allowance?" />
        <ElevioArticle articleId="57" text="Can I still receive calls and SMS when my prepaid plan has expired?" />
        <ElevioArticle articleId="58" text="How do I add more data to my prepaid plan?" />
        <ElevioArticle articleId="59" text="Does a prepaid line expire?" />
      </div>
    );
  };

  const Esim = () => {
    return (
      <div>
        <ElevioArticle articleId="60" text="What is eSIM?" />
        <ElevioArticle articleId="62" text="How does eSIM activation work?" />
        <ElevioArticle articleId="61" text="What plans are available with eSIM at PhoneBox?" />
        <ElevioArticle articleId="63" text="Are all devices compatible with eSIM?" />
      </div>
    );
  };

  const PortIn = () => {
    return (
      <div>
        <ElevioArticle articleId="76" text="Can I port in my phone number from my previous mobile carrier when I already have my PhoneBox's account activated?" />
        <ElevioArticle articleId="77" text="Can I port-in a landline phone number to PhoneBox?" />
      </div>
    );
  };

  const BeforeSigningUp = () => {
    return (
      <div>
        <ElevioArticle articleId="64" text="How will I know my Phone number?" />
        <ElevioArticle articleId="65" text="Can I change the start date of the service with PhoneBox?" />
        <ElevioArticle articleId="66" text="Is my device compatible with PhoneBox network?" />
        <ElevioArticle articleId="67" text="What is data overage fee and how does it work?" />
        <ElevioArticle articleId="68" text="Can I sign-up for a PhoneBox plan if I am under 18 years old?" />
        <ElevioArticle articleId="69" text="Can I choose my phone number when I sign up?" />
      </div>
    );
  };
  const USASimCards = () => {
    return (
      <div>
        <ElevioArticle articleId="71" text="Can I use an international credit card to pay for a US phone number?" />
        <ElevioArticle articleId="72" text="Can I pay for the US phone number in cash?" />
        <ElevioArticle articleId="73" text="Do I have to contact Phonebox to terminate a US plan?" />
        <ElevioArticle articleId="74" text="How good is the Phonebox network coverage in the US?" />
        <ElevioArticle articleId="75" text="What is PhoneBox network speed in the US?" />
        <ElevioArticle articleId="82" text="Do PhoneBox USA plans include international calling services?" />
      </div>
    );
  };
  return (
    <div>
      <OuterBar onClick={() => rotateArrow()}>
        <Text style={{ fontWeight: 450 }}>
          {props.itemName}
        </Text>
        <MyImage
          src="/images/support/right-arrow.png"
          isArrowRotated={isArrowRotated}
          height={25}
          width={25}
          alt="right-arrow"
        />
      </OuterBar>
      {props.itemName === "Sign Up" && isArrowRotated ? (
        <SignUp />
      ) : props.itemName === "Account Changes" && isArrowRotated ? (
        <AccountChanges />
      ) : props.itemName === "Leaving/Port Out/Suspension" && isArrowRotated ? (
        <LeavingPortOutSuspension />
      ) : props.itemName === "General Information About PhoneBox" &&
        isArrowRotated ? (
        <GeneralInformation />
      ) : props.itemName === "Complaints and Feedback" && isArrowRotated ? (
        <ComplaintsAndFeedback />
      ) : props.itemName === "Multiline Accounts" && isArrowRotated ? (
        <MultilineAccounts />
      ) : props.itemName === "Shipping" && isArrowRotated ? (
        <Shipping />
      ) : props.itemName === "Network/Technical Support" && isArrowRotated ? (
        <NetworkTechnicalSupport />
      ) : props.itemName === "Billing Inquiry" && isArrowRotated ? (
        <BillingInquiry />
      ) : props.itemName === "Plans/Roaming/Promotion" && isArrowRotated ? (
        <PlansRoamingPromotion />
      ) : props.itemName === "eSIM" && isArrowRotated ? (
        <Esim />
      ) : props.itemName === "Port In" && isArrowRotated ? (
        <PortIn />
      ) : props.itemName === "Before Signing Up" && isArrowRotated ? (
        <BeforeSigningUp />
      ) : props.itemName === "USA SIM Cards" && isArrowRotated ? (
        <USASimCards />
      ) : null}
    </div>
  );
};


const BAR_LENGTH = "80%";

const MyImage = styled.img<{ isArrowRotated: boolean }>`
  display: block;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;

  -webkit-transform: ${(props) =>
    props.isArrowRotated ? "rotate(90deg)" : "rotate(0deg)"};
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
`;

const OuterBar = styled.div`
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  width: ${BAR_LENGTH};
  padding: 10px 20px;
  margin: 10px 0px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  p {
    margin: 0px;
  }
  :hover {
    cursor: pointer;
  }

  @media (max-width: 768px) {
    width: 32em;
  }
  @media (max-width: 620px) {
    width: 30em;
  }
  @media (max-width: 560px) {
    width: 28em;
  }
  @media (max-width: 520px) {
    width: 26em;
  }
  @media (max-width: 480px) {
    width: 24em;
  }
  @media (max-width: 440px) {
    width: 22em;
  }

  @media (max-width: 390px) {
    width: 20rem;
  }
`;

const Text = styled.p``;

export default ElevioCategory;