import React from "react";
import styled from "styled-components";

interface BlackFridayDataSlashProps {
  data: number;
}

const BlackFridayDataSlash: React.FC<BlackFridayDataSlashProps> = (props: BlackFridayDataSlashProps) => {
  return (<BlackFridayDataSlashTitle>{props.data}GB</BlackFridayDataSlashTitle>);
}

const BlackFridayDataSlashTitle = styled.h5`
  margin-top: -30px;
  text-decoration: line-through;
  color: #A0A0A0;
  font: normal normal 300 25px/30px Poppins;

  @media (max-width: 768px) {
    font: normal normal 300 15px/20px Poppins !important;
  }
`
export default BlackFridayDataSlash;