// extracted by mini-css-extract-plugin
export var HeadingDescription = "PlanSelectCard-module--HeadingDescription--e72ad";
export var blackFridayButtonContainer = "PlanSelectCard-module--blackFridayButtonContainer--cde5e";
export var blackFridayPlanDescription = "PlanSelectCard-module--blackFridayPlanDescription--a458f";
export var blackFridayPlanDetailCard = "PlanSelectCard-module--blackFridayPlanDetailCard--24777";
export var blackFridayPlanFirstRow = "PlanSelectCard-module--blackFridayPlanFirstRow--fe3df";
export var blackFridayPlanItem = "PlanSelectCard-module--blackFridayPlanItem--6754f";
export var blackFridayPlanSeeMoreInline = "PlanSelectCard-module--blackFridayPlanSeeMoreInline--47287";
export var blackFridayPlanType = "PlanSelectCard-module--blackFridayPlanType--4a6fb";
export var blackFridaySeeMoreContainer = "PlanSelectCard-module--blackFridaySeeMoreContainer--57994";
export var buttonContainer = "PlanSelectCard-module--buttonContainer--37e02";
export var contentMainDescription = "PlanSelectCard-module--contentMainDescription--730d2";
export var planBorder = "PlanSelectCard-module--planBorder--1dc89";
export var planDescription = "PlanSelectCard-module--planDescription--4b863";
export var planDetailCard = "PlanSelectCard-module--planDetailCard--104c5";
export var planDetailContainer = "PlanSelectCard-module--planDetailContainer--27ee3";
export var planDetailDescriptionView = "PlanSelectCard-module--planDetailDescriptionView--bd8ae";
export var planFirstRow = "PlanSelectCard-module--planFirstRow--0bb0f";
export var planItem = "PlanSelectCard-module--planItem--8d6f7";
export var planPromo = "PlanSelectCard-module--planPromo--a5169";
export var planSeeMoreInline = "PlanSelectCard-module--planSeeMoreInline--08566";
export var planType = "PlanSelectCard-module--planType--f3ee6";
export var planTypeUnlimited = "PlanSelectCard-module--planTypeUnlimited--9671e";
export var promoTextItem = "PlanSelectCard-module--promoTextItem--5ad4c";
export var seeMoreContainer = "PlanSelectCard-module--seeMoreContainer--25be7";
export var verticalText = "PlanSelectCard-module--verticalText--8e883";